.patient-registration-form {
  padding: 10px;
  background-color: var(--projectwhite);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
}
.custom-header {
  width: 98%;
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  padding: 10px 17px 10px 10px;
  border-radius: 5px;
  text-align: start;
}

.custom-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  position: relative;
  padding: 12px;
}

.custom-label-title {
  flex: 2;
  font-weight: bold;
  margin-right: 0px;
  text-align: start;
  font-size: 15px;
  width: 250px;
  color: var(--labelcolor);
}
.custom-form-row {
  width: 31%;
}
.custom-select-title {
  width: 50%;
  outline: none;
  border: none;
}
.custom-select-doctor-name {
  outline: none;
  border: none;
  width: 50%;
}
.custom-form-row input {
  flex: 2;
  border: none;
  border-bottom: 0px solid #333;
  outline: none;
  padding: 5px;
  width: 108px;
  font-size: 13px;
}

.patient-info-container,
.patient-contact-container,
.appointment-details-container,
.doctor-info-container {
  border-bottom: 1px solid var(--ProjectColor);
  display: flex;
  flex: 2;
}

.button-container {
  text-align: center;
  padding: auto;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}
.btncon {
  padding: 10px;
  width: 110px;
  border-radius: 25px;
  background-color: var(--ProjectColor);
  border: none;
  color: var(--labelcolor);
  margin-top: 10px;
  margin-bottom: 10px;
}

.btncon:hover {
  background-color: var(--ProjectColorhover);
  cursor: pointer;
  color: var(--projectwhite);
}

::placeholder {
  color: rgba(0, 0, 0, 0.4);
}




@media (max-width: 970px) {
  .patient-registration-form {
    padding: 5px;
    /* margin-bottom: 20px; */
  }
  .custom-header {
  width: 98%;
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  padding: 10px 0px;
  padding-left: 5px;
  border-radius: 5px;
  text-align: start;
  margin-top: 5px;
  }
  .patient-info-container,
  .patient-contact-container,
  .appointment-details-container,
  .doctor-info-container {
    display: flex;
    flex-direction: column;
    border-bottom: 0px !important;
  }

  .custom-form-row {
    font-size: 12px;
    border-bottom: 1px solid var(--ProjectColor);
    width: 90% !important;
  }
  .custom-select-title,
  .custom-select-doctor-name {
    width: 50%;
    outline: none;
    border: none;
    padding: 5px;
  }

  .kit {
    display: block;
  }

 
  #tt {
    position: relative;
  }
  .navigation{
    display: none;
  }
  .tog {
    background-color: var(--ProjectColor);
    border: transparent;
    border-radius: 8px;
 }

 .button-container{
  gap: 10px;
 }
}
