/* Master.css */
body {
    overflow-x: hidden;
}

.Supplier_Master_form_Con_1,
.Supplier_Master_form_sub_con_div_1 {

    width: 100%;
    display: flex;
    flex-direction: column;



}

.Supplier_Master_form_sub_con_div_1 {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;


}

.Supplier_Master_form_Container, .Supplier_Master_Container {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #fff;
    gap: 20px;
}

.manufacturer-content {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

}

.Supplier_Master_form_Container_Header {
    height: 40px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: var(--ProjectColor);;
    /* margin: 5px; */
}

.Supplier_inputss_forms{
    width: 295px;
    /* height: 50px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-bottom:  1px solid var(--ProjectColor);;
}
.Supplier_inputss_forms label{
    width: 120px;
    color: var(--labelcolor);;
}
.Supplier_inputss_forms input,.Supplier_inputss_forms select{
    width: calc( 100% - 120px);
    border: 0px;
    outline: 0px;
    background-color: transparent;
}
.Supplier_Master_form_Con_div_Row_input {
    width: 80%;
    border: 1px solid grey;
    border-radius: 5px;
    height: 25px;
    padding: 0px 10px;
    box-sizing: border-box;
    outline: 0px;


}
.Supplier_Concern_form_choose{
    background-color: var(--ProjectColor);;
    padding: 5px 10px;
    width: 100px;
    border-radius: 5px;
    outline: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-clear-button,
input[type="number"]::-webkit-search-cancel-button {
  display: none !important;
}




.Supplier_Master_form_Con_div_Row_label {
    color: var(--ProjectColor);;
    font-size: 14px;
    /* Set your desired font size */
    font-weight: bold;
    display: flex;
}


.Supplier_Master_form_Con_Row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    justify-content: flex-start;
}



.master-purchase-btn {
    text-align: center;
    padding: 10px;
    justify-content: center;
    display: flex;
    gap: 30px;
}

.submit-master {
    padding: 8px 20px;
    border-radius: 30px;
    background-color: var(--ProjectColor);;
    border: 1px solid var(--ProjectColor);;
}

.submit-master:hover {
    background-color: var(--ProjectColorhover);
    cursor: pointer;
}

.Supplier_Master_form_Con_div_Row {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.Supplier_Master_form_Con_div_Row1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.row-buttons {
    display: flex;
    flex-direction: row;
    padding-left: 40%;
    height: 30px;
}

.Manu_Manu {


    height: 25px;
    width: 35%;
}
.Supplier_Master_form_Save_button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Supplier_Master_form_Save_button {
    width: 80px;
    height: 30px;
    background-color: var(--ProjectColor);
    border: none;
    border-radius: 5px;
}

.Supplier_Master_form_Save_button:hover {
    background-color:var(--ProjectColorhover);
}

.Supplier_Master_form_sub_con {

    width: 100%;
    display: flex;
    gap: 10px;
    box-sizing: border-box;
}

.Supplier_Master_form_sub_con_div {
    width: 50%;

}

.Supplier_Master_form_sub_con_div_1_toggle {
    width: 200px !important;
    height: 30px !important;
    font-size: 10px !important;
}

@media screen and (max-width: 768px) {

    body,
    html {
        overflow-x: hidden;
    }

    .Supplier_Master_form_sub_con_div_1_toggle { 
        width: 140px !important;
        height: 30px !important;
        font-size: 8px !important;
        font-weight: 600 !important;
        align-items: center !important;
    }

    .Supplier_Master_form_Con_div_Row_label {
        color: var(--ProjectColor);;
        font-size: 14px;
        /* Set your desired font size */
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 10px;
    }


    .Supplier_Master_form_Con_Row {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        justify-content: center;
    }

    .Supplier_Master_form_Con_div_Row_input {
        width: 60%;
        height: 25px;

    }

    .Supplier_Master_form_Con_div_Row1 {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .master-inventory-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .Supplier_Master_form_Con_1 {

        width: 90%;

    }

    .Supplier_Master_form_Con_div_Row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;

    }

    .Supplier_Master_form_sub_con {

        width: 100%;
        display: flex;
        gap: 10px;
        box-sizing: border-box;
        flex-direction: column;
    }

    .Supplier_Master_form_sub_con_div {
        width: 100%;

    }
}



/* raise popup */

.Raise_indent_popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}
.Raise_indent_popup_con{
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 350px;
    width: 100%;
    overflow: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Raise_indent_form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px ;
    box-sizing: border-box;
    gap: 20px;
}
.Raise_indent_div{
    width: 300px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}
.Raise_indent_div label{
    width: 100px;
    color: var(--ProjectColor);;
    display: flex;
    justify-content: space-between;
}
.Raise_indent_div input{
    outline: 0px;
    height: 25px;
}
.Raise_indent_btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Raise_indent_btn button{
    width: 100px;
    background-color: var(--ProjectColor);;
    padding: 8px 10px;
    outline: 0px;
    border: 0px;
    border-radius: 10px;
}
.Supplier_Master_con_1 {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    padding: 10px 0px;
  }
.Supplier_Master_h_head {
    width: 98%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 5px;
    color: #000;
    background-color: var(--ProjectColor);;
  }
  .Supplier_Master_btn_1 {
    border: none;
    background-color: var(--ProjectColor);;
    color: #fff;
    cursor: pointer;
    width: 100px;
    border-radius: 25px;
    height: 35px;
  }
.inputColumn{
    width: 150px;
    height: 20px;
}

.appointment_rejectIndent{
    background-color: #fff;
    width: max-content;
    height: max-content;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow: auto;
    position: relative;
}
.appointment{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.appointment_rejectIndent_head{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--ProjectColor);;
}