/* Basic reset for margin and padding */
body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

/* Header styles */
.header,
.headersmc {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  display: flex;
  align-items: center;
  padding: 5px;
  height: 50px;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.headersmc {
  padding: 0px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.headersmc h1 {
  margin: 0px;
  padding: 0px;
  /* font-weight: 700; */
  font-size: 20px;
}
.header img {
  width: 40px;
  height: 40px;
  padding: 5px;
}

.mrgn_logo img{
  width: 35px;
   height: 35px;
   padding: 5px;
   margin-left: 13px;
  }


.headersmc img {
  width: 30px;
  height: 30px;
  /* padding: 5px; */
}

.icn-tle-cntrfx {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Add this to your existing CSS */

.header_name {
  font-size: 24px;
  font-weight: bold;
  margin: 0; /* Add this line to remove default margin */
}

.header_container_sidebar .header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Center the clinic name */
  padding: 5px;
  height: 50px;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.header_container_sidebar1 .headersmc {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Center the clinic name */
  padding: 0px 10px;
  box-sizing: border-box;
  height: 60px;
}

.header_container_sidebar1 .headersmc h1 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.logo {
  margin-right: 10px; /* Add this line to provide spacing for the clinic logo */
}

.toggled_box_header {
  width: 140px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  background-color: var(--ProjectColor);
  position: absolute;
  top: 65px;
  right: 0px;
  font-size: 12px;
}
/* .toggled_box_header_txt{
  color: gray;
} */
.toggled_box_header_txt:hover {
  background-color: var(--ProjectColorhover);
  color: var(--projectwhite);
}
.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.logosmc {
  width: 30px;
  height: 30px;
}
.header_name {
  font-size: 24px;
  font-weight: bold;
}

.select-container {
  margin-left: auto;
  margin-right: 30px;
}

/* Select styles */
.select-style {
  padding: 5px;
  /* padding-right: 10px; */
  border: 1px solid black;
  border-radius: 5px;
  background-color: var(--ProjectColor);
  font-size: 14px;
  color: var(--labelcolor);
  /* margin-right: 50px; */
}
.header_container_sidebar1 {
  display: none;
}
.header_container_sidebar {
  display: block;
}

.flx-lct-icn4 {
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-right: 10px; */
}

.prfl-lctn-adjs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* gap: 5px;   */
}

.loc_input {
  padding: 0px 15px 0px 15px;

  background-color: var(--ProjectColor);
  font-size: 14px;
  color: var(--labelcolor);
  border: 1px solid white;
  border-radius: 5px;
}

.loc_icon {
  font-size: 20px;
}

/* Select styles */
.select-container {
  position: relative;
}

.select-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-container {
  position: absolute;
  top: 45px;
  right: 500px;
  left: 0;
  width: 250px;

  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.select-container .select-style {
  padding: 10px;
  position: absolute;
  width: 150px;
  right: 25px;
  top: 20px;
  /* height: 80px; */
}

.selectrole {
  padding: 5px;
  cursor: pointer;
}

.selectrole:hover {
  background-color: var(--ProjectColorhover);
  color: white;
}

.select-style {
  padding: 5px;
  padding-right: 10px;
  border: 1px solid var(--projectwhite);
  border-radius: 5px;
  background-color: var(--ProjectColor);
  font-size: 14px;
  color: var(--labelcolor);
  margin-right: 92px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.eifjidfj span {
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .header_container_sidebar {
    display: none;
  }
  .header_container_sidebar1 {
    display: block;
  }

  .select-container {
    margin-right: 0px;
  }

  .headersmc img {
    width: 35px;
    height: 35px;
    /* padding: 5px; */
    border-radius: 50%;
  }

  .logosmc {
    width: 120px !important;
    height: 35px !important;
    border-radius: 5px !important;
  }

  .loc_icon {
    font-size: 23px;
    margin-top: 5px;
    /* padding-right: 10px; */
    margin-right: 10px;
  }

  .toggled_box_header {
    border: 1px solid black;
    right: 10px;
  }

  .select-container img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }

  .select-container .select-style {
    padding: 10px;
    position: absolute;
    width: 150px;
    right: 85px;
    top: 15px;
    /* height: 80px; */
  }
}
