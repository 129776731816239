.twsrtw3 {
    margin-top: 3px ;

    border-radius: 80px;



}

.tfdrtdf6{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 15px;
    background-color: transparent;
height: 10px;
}

.sxsxe {

    color:white;
    border: none;
    outline: none;
    background-color:  var(--ProjectColor);
    font-size: 15px;
    height: 20px;
    cursor: pointer;
}