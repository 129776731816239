.treatment_total_container{
  padding: 10px;
}


.treatment_container{
  /* height: 500px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px ;
  box-sizing: border-box;
  gap: 10px;
}
.treatcon_1{
  background-color: transparent;
  height: 300px;
  width: 350px;
  display: grid;
  place-items: center;
}
.treatcon_2{
  height: 300px;
  /* width: calc(100% - 300px); */
  padding: 5px 10px ;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.treatcon_body{
  height: 100%;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */
  flex-direction: column;
}
.treatcon_body_1{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
}
.treatcon_body_1 label{
  color: var(--labelcolor);
  font-size: 14px;
  font-weight: bold;

  width: 200px;
  display: flex;
  justify-content: space-between;
}
.RegisForm_1 textarea{
  outline: 0px;
  border: 0px;
  margin-left: 5px;
  padding: 5px;
  width: 50% !important;
  box-shadow: 1px 1px 5px 5px rgb(212, 229, 229);
  background-color: transparent;
  height: 20px;
}


.treatcon_body_2{
  display: flex;
  height: 65px;
}
.treatcon_body_3{
  display: flex;
  height: 45px;
  gap: 10px;
  
}
.treatcon_body_2 label,.treatcon_body_3 label{
  color: var(--labelcolor);
  width: 140px;
  display: flex;
  justify-content: space-between;
}
.treatcon_body_2  textarea{
  height: 55px;
  width: 400px;
  outline: 0px;
  border: 0px;
  margin-left: 10px;
  box-shadow: 1px 1px 5px 5px rgb(212, 229, 229);
  background-color: transparent;
}
.treatcon_body_3 input{
  border: 0px;
  outline: 0px;
  height: 25px;
  width:350px;
  box-shadow: 1px 1px 5px 5px rgb(212,229,229);
}
.trt_input-dlist{
  width: 100%;
  height: 20px;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  box-shadow: 1px 0px 10px 1px rgb(204, 211, 211);

} 

 .with-icd-his textarea{
  width: 52%;
  height: 25px;
  padding: 5px;
  margin-left: 5px;

}

.upload-file-trtmt label{
color: var(--labelcolor);
width: 530px;
/* border-bottom: 1px solid var(--ProjectColor) ; */


}

.choselabelclr{
  color: var(--labelcolor) !important;

}


.treatcon_image{
  height: 280px;
  width: 300px;
  padding: 10px;
  box-sizing: border-box;
}
.treatcon_image_1{
  height: calc(100% - 40px);
}
.treatcon_image_1 img{
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.treatcon_label{
  height: 40px;
  display: grid;
  place-items: center;
  color: var(--labelcolor);
  box-shadow: 1px 0px 5px 1px rgb(204, 211, 211);
}

.anot-withhh{
  width: 323px;
}
.treatment_buttons{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* margin-top: 20px; */
}
.treatment_buttons button{
  width: 100px;
  height: 30px;
  border-radius: 15px;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  background-color: var(--ProjectColor);
}
.treatment_buttons button:hover{
  background-color: var(--ProjectColorhover);
  color: var(--projectwhite);
}
.showcamera {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.sideopen_showcamera{
position: fixed;
top: 0;
left: 250px;
width: calc(100% - 250px);
height: 100%;
background: rgba(0, 0, 0, 0.6);
display: flex;
justify-content: center;
align-items: center;}
.showcamera_1 ,.sideopen_showcamera_1{
  background-color: #fff;
  width:67%;
  height: 77%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* text-align: center; */
  position: relative;
  /* padding-top: 18px; */
}

.btncon_add {
  padding: 8px 20px;
  border-radius: 30px;
  background-color: var(--ProjectColor);
  border: 1px solid var(--ProjectColor);
  cursor: pointer;
}

.btncon_add:hover {
  background-color: var(--ProjectColorhover);
  color: var(--projectwhite);
}

.anoted_img{
  width: 400px;
  height: 350px;
}

.pro-tak-pic{
  width: 140px !important ;
  height: 150px;
}

/* .pro-tak-head img label{
  width: 30px;
  height: 150px;
} */
/* .pro-tak-pic img label{
  width: 500px;
  height: 100px;
} */

@media screen and (max-width:1280px) {
  .treatment_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 10px 0px;
    gap: 15px;
    width: 100%;
  }

  .treatcon_1{
    background-color: transparent;
    height: auto;
    width: 500px;
    display: grid;
    place-items: center;
  }

  .treatcon_2{
    flex-direction: column;
    height: auto;
    padding: 10px 0px;
    width: 350px;
  }
  .treatcon_image{
    width: 320px;
  }
  .anoted_img{
    width: 400px;
    height: 350px;
  }
}

/* @media screen and (max-width:768px) {
  .treatment_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px 0px;
    gap: 80px;
  }

  .treatcon_1{
    background-color: transparent;
    height: auto;
    width: 500px;
    display: grid;
    place-items: center;
  }

  .treatcon_2{
    flex-direction: column;
    height: auto;
    padding: 10px 0px;
    width: 350px;
  }
  .treatcon_image{
    width: 320px;
  }
  .anoted_img{
    width: 400px;
    height: 350px;
  }
} */

@media screen and (max-width:575px) {
  .treatment_container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    gap: 25px;
    padding: 10px 10px;
  
  }
  .treatcon_1{
    background-color: transparent;
    height: 300px;
    width: 500px;
    display: grid;
    place-items: center;
  }

  .with-icd-his textarea{
    width: 300px;
    height: 25px;
    padding: 5px;
  }
  .treatcon_1 textarea{
    height: 30px;
    width: calc(100% - 110px);
  }
  .treatcon_2{
    flex-direction: column;
    height: auto;
    padding: 10px 0px;
    width: 280px;
  }
  .treatcon_image{
    width:250px;
 }
 .anoted_img{
  width: 300px;
  height: 300px;
}
.pro-tak-pic{
  width: 80px !important ;
  height: 110px;
}
.anot-withhh{
  width: 238px;
}

.treatcon_body_1{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 55%;
  padding: 10px;
}
.trt_input-dlist{
  width: 65%;
}

.treatcon_body_1 label{
  color: var(--labelcolor);
  font-size: 14px;
  font-weight: bold;

  width: 200px;
  display: flex;
  justify-content: space-between;
}

.treatcon_body_1 textarea{
  width: 100% !important;
}

.treatment_buttons{
  margin-top: 25px;
}

}