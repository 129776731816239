@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');


body{
  background: #f4f3f2;  
  background: -webkit-linear-gradient#F59040;  
  background: linear-gradient#F59040; 
  
}
:root{
  --ProjectColor:rgb(214, 10, 214);
  --ProjectColorhover:rgba(214, 10, 214, 0.37);
  --labelcolor:#000;
  --projectwhite:white;
  --selectbackgroundcolor: rgba(214, 10, 214, 0.308);
  --fontsize: 12px;
}




.toast-container-over-header{
  /* background-color: aqua;
  width: 200px;
  height: 50px; */
  position: absolute;
  top: 20px;
  /* bottom: 0; */
  right: 10px;
  z-index:9999;
}
