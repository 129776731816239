
  .Stoct_Product_master {
   padding: 10px;
  }
  
  .Stoct_Product_master_form_row_div select {
    height: 25px;
    /* width: 170px; */
    width: 175px;
    outline: 0px;
    /* border: 1px solid grey; */
    padding: 0px 5px;
    box-sizing: border-box;
    margin-left: 0px;

  }
  
  .Stoct_Product_master_Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
  }
  .Add_items_Purchase_Master{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:grey;
    font-size: 17px;
    font-weight: 600;
    height: 50px;
  }
  
  .Stoct_Product_master_head {
    height: 40px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    gap: 20px;
    background-color: var(--ProjectColor);;
  }
  
  .Stoct_Product_master_head_h3 {
    padding-left: 10px;
    margin: 5px;
    color: #000;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .Stoct_Product_master_form_Input {
    width: 160px;
    height: 25px;
    outline: 0px;
    border: 1px solid var(--ProjectColor);
    border-radius: 5px;
    padding: 0px 5px;
  }
  
  .Stoct_Product_master {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
  }
  
  .Stoct_Product_master_form_row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .Product_Master_h_head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: var(--ProjectColor);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0px 20px;
    box-sizing: border-box;
  
}
.Product_Master_div_select{
    font-weight: 600;
    font-size: 16px;
    /* color: white; */
}
.Product_Master_div_select select{
    border: 0px;
    outline: 0px;
    background-color: var(--ProjectColor);;



}
.Product_Master_div_select_opt{
    color: white;
  }
  .Product_Master_div_select_opt option{
    color: black;
  }
  .Stoct_Product_master_form {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
  }
  
  .Stoct_Product_master_form_Label {
    font-weight: bold;    
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 165px;
    color: var(--labelcolor);
    background-color: var(--ProjectColor);
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
  }
  
  
  .Stoct_Product_master_form_Save {
    text-align: center;
    padding: 10px;
    justify-content: center;
    display: flex;
    gap: 30px;
  }
  
  .Stoct_Product_master_form_Save_button {
    padding: 8px 20px;
    border-radius: 30px;
    width: 100px;
    outline: 0px;
    background-color: var(--ProjectColor);;
    border: 1px solid var(--ProjectColor);;
  }
  
  .Stoct_Product_master_form_Save_button:hover {
    background-color: var(--ProjectColorhover);
    cursor: pointer;
  }
  
  
  
  
  
  .Stoct_Product_master_form_row_div {
    width: 23%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
  }
  

  @media screen and (max-width: 1000px) {
  
    .Stoct_Product_master_form_row {
      display: flex;
      flex-direction: column;
      width: 75%;
      gap: 20px;
    }
  

    .Stoct_Product_master_form_Label {
      font-weight: bold;    
      display: flex;
      align-items: center;
      width: 60%;
      color: var(--labelcolor);
      background-color: var(--ProjectColor);
      border-radius: 5px;
      padding: 5px;
      font-size: 15px;
    }

    .Stoct_Product_master_form_Input {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 60%;
      height: 25px;
      outline: 0px;
      border: 1px solid var(--ProjectColor);
      border-radius: 5px;
      padding: 0px 5px;
    }

    .Stoct_Product_master_form_row_div select {
      height: 25px;
      /* width: 170px; */
      width: 61%;
      outline: 0px;
      /* border: 1px solid grey; */
      padding: 0px 5px;
      box-sizing: border-box;
      margin-left: 0px;
  
    }

    .Stoct_Product_master_form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
    .Product_Master_h_head{
        font-size: 13px;
    }
    .Product_Master_div_select {
        font-size: 13px;
    }
    .Stoct_Product_master_form_row_div {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
  }

  @media screen and (max-width: 500px){
    .Stoct_Product_master_form_row {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }
  

    .Stoct_Product_master_form_Label {
      font-weight: bold;    
      display: flex;
      align-items: center;
      width: 100%;
      color: var(--labelcolor);
      background-color: var(--ProjectColor);
      border-radius: 5px;
      padding: 5px;
      font-size: 15px;
    }

    .Stoct_Product_master_form_Input {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 25px;
      outline: 0px;
      border: 1px solid var(--ProjectColor);
      border-radius: 5px;
      padding: 0px 5px;
    }

    .Stoct_Product_master_form_row_div select {
      height: 25px;
      /* width: 170px; */
      width: 100%;
      outline: 0px;
      /* border: 1px solid grey; */
      padding: 0px 5px;
      box-sizing: border-box;
      margin-left: 0px;
  
    }

    .Stoct_Product_master_form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
    .Product_Master_h_head{
        font-size: 13px;
    }
    .Product_Master_div_select {
        font-size: 13px;
    }
    .Stoct_Product_master_form_row_div {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

  }