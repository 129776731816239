.yuuuuy {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  width: 100%; /* Set the width to 100% */
  /* max-width: max-content; Add a max-width to prevent it from becoming too wide */
  margin: 0 auto; /* Center the element horizontally */
  position: absolute;
  top: 0px;
}
.Ip_room_avail_container {
  /* position:absolute; */
  /* top: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
}
.tooltip-trigger{
  width: 350px;
}

.Inv_form_div_container_pr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.Inv_form_row_pr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 950px; /* Change width to max-width */
  gap: 30px;
}

.Inv_form_div_pr input {
  width: 150px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: 0px;
}

.Inv_form_div_pr {
  width: 100%;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  align-items: center;
}

.Inv_form_div_pr label {
  color: var(--labelcolor);
  font-size: 15px;
  font-weight: bold;
}

.room_avail {
  display: flex;
  padding-top: 25px;
  justify-content: space-around;
}

.room_avail select {
  width: 150px;
  outline: none;
}

.room_avail label {
  padding: 35px;
  font-weight: bolder;
}

.roomdetail {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px;
}

.Rooms_avail_table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Rooms_avail_table table {
  width: 98%;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 2px #c4b9b9;
  height: 210px;
}

.Rooms_avail_table table thead {
  color: var(--ProjectColor);
}

.Rooms_avail_table th,
.Rooms_avail_table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.Rooms_avail_table tr:nth-child(even) {
  background-color: rgba(224, 221, 223, 0.384);
}

.Rooms_avail_table tr:hover {
  background-color: #5b595917;
}

.Rooms_avail_card {
  width: 100%; /* Change width to 100% */
  background-color: rgba(216, 216, 216, 0.055);
  display: flex;
  padding: 10px 0px;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
  padding: 0px 10px;
}

.Rooms_avail_carditems {
  border: 1px solid rgb(206, 203, 205);
  display: grid;
  border-radius: 10px;
  place-items: center;
}

.Rooms_avail_carditems:hover {
  box-shadow: 1px 1px 5px 2px #c4b9b9;
}

.Rooms_avail_carditems h5 {
  font-size: 18px;
}

.Rooms_avail_carditems p {
  display: flex;
}

.Rooms_avail_carditems_bookedIcon {
  height: 3rem !important;
  color: red;
}

.Rooms_avail_carditems_availableIcon {
  height: 3rem !important;
  color: green;
}

.Inv_form_div_pr select {
  width: 150px;
  padding: 5px 10px;
  font-size: 14px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: 0px;
}

.Rooms_avail_card_neww label {
  color: var(--labelcolor);
  width: 85px;
  display: flex;
  justify-content: space-between;
}

.Rooms_avail_card_container {
  padding: 5px 5px !important;

}
.Rooms_avail_card_container_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;

}
.Rooms_avail_card_neww {
  display: flex;
  gap: 5px;
  width: 165px;
}

.Rooms_avail_card_one {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Rooms_avail_card_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Rooms_avail_carditems_availableIcon {
  font-size: 22px !important;
  height: 20px !important;
}

.Rooms_avail_card_btns {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}
.QuickStock_container_header {
  width: 100%;

  /* margin-top: 35px; */

}

.eddwm label{
  display: flex;
  justify-content: space-between;
}

.IP_popup_Regis{

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.IP_popup_content_regis{
  
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* max-width: 80%; */
  width: 80%;
  overflow: auto;
  height: 77%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_rom_mage{
  color: var(--ProjectColor);
  font-size: 16px;
  margin: 10px;

}
@media (max-width: 1225px) {
  .QuickStock_container_header {
    width: 100%;

  }

  .Rooms_avail_table {
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Inv_form_div_container_pr {
    gap: 20px;
  }

  .Inv_form_row_pr {
    flex-direction: column;
    gap: 20px;
  }

  .Inv_form_div_pr {
    width: 100%;
  }

  .Inv_form_div_pr input {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .Inv_form_div_container_pr {
    gap: 20px;
  }

  .Inv_form_row_pr {
    flex-direction: column;
    gap: 20px;
  }

  .Inv_form_div_pr {
    width: 100%;
}

  .Inv_form_div_pr input {
    width: 100px;
  }

  .Rooms_avail_table table {
    width: 98%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 1px 5px 2px #c4b9b9;

    overflow-x: auto;
    height: 200px;
    font-size: 13px;

  }
  .Inv_form_div_pr select{
    width: 120px;
  }
}
@media (max-width: 500px) {
  .tooltip-trigger{
    width: 300px;
  }
  .Rooms_avail_card_container_container,.Rooms_avail_table table td{
   font-size: 11px !important;
  }
}
