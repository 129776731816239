#panel1bh-header{
  color: var(--labelcolor) !important;
  font-size: 19px !important;
  text-align: left;
  position: relative;
  z-index: 0;
  /* margin-bottom: 5px; */
}

.Register_RegisFormcon{
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 0px !important;
}

.Register_RegisFormcon .RegisForm_1{
  height: 40px;
  border-bottom: 1px solid var(--ProjectColor);
}
.RegisFormcon1{
  justify-content: flex-start;
  gap: 0px;
}


.RegisForm_111{
  height: 40px;
  border-bottom: 1px solid var(--ProjectColor);

}





.RegisterForm_2  {
  display: flex;
  align-items: center;
}
.RegisterForm_2 label{
  color: var(--labelcolor);
}

.RegisterForm_2 label:hover{
color: var(--labelcolor);
background-color: var(--ProjectColorhover);

}



.Register_btn_con{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 10px;
  gap: 20px;
  margin-top: 15px;
}


.showcamera_takepic {

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.sideopen_showcamera_takepic{
position: fixed;
top: 0;
z-index: 3;
left: 250px;
width: calc(100% - 250px);
height: 100%;
background: rgba(0, 0, 0, 0.6);
display: flex;
justify-content: center;
align-items: center;
}

.showcamera_1_takepic1 ,.sideopen_showcamera_1_takepic1{
  background-color: #fff;
  width:75%;
  height: 77%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* text-align: center; */
  position: relative;
  /* padding-top: 18px; */
}


#CapturedFile1,#CapturedFile2,
#CapturedFile3 {
display: none; /* Hide the file inputs */

}


.hiden-nochse-file{
  display: none;
}

.txt-ara-lclprntm{
  height: 30px;
  padding: 5px;

 
  
}

.uyufd2{
  margin-bottom: 10px;
  margin-top: 0px;
}

/* @media screen and (max-width:1100px) {



  .RegisForm_1{
      height: 40px;
    
      width: 450px;
      display: flex;
      justify-content: flex-start;

}
.RegisForm_1 label{
  width: 168px;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

}
#hide_div_regg{
  display: none !important;
}
}

@media screen and (max-width:600px){
 
  #hide_div_regg{
    display: none !important;
  }
} */