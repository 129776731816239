.userhole_container {
  width: calc(100% - 230px); 
 width: 100%;
 margin: 0 auto;
 border: none;
 font-family: Arial, sans-serif;
 color: var(--labelcolor);
 font-size: 15px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 margin-top: 10px;
 
}


.user_patienthead {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  padding: 10px 10px 10px 10px;
  margin: 10px 10px;
  border-radius: 5px;
  text-align: start;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  
 }


 .user_row1 {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-bottom: 20px;
   border-bottom: 1px solid var(--ProjectColor);
   margin-top: 20px;
   width: 100%;
   padding: 3px;
  
}


.user_input2 {
  display: flex;
  width: 350px;

 
 }

 .user_input2 label {
  font-weight: bold;
  text-align: start;
   margin-bottom: 5px;
   padding-top: 7px;
   width: 100px;
   padding: 5px;
   margin-right: 30px;

 
 }


 .user_input2 input {
  border: none;
  outline: none;
  width: 200px;
  font-size: 13px;
  padding: 5px;
  
 }

 .user_input2 select{
  width: 100px;
  border: none;
  outline: none;
 }

 .uerregisfooter {
  text-align: center;
  display: flex;
  justify-content: center;
 
 }
 
 .uerregisfooter button {
  padding: 10px;
  width: 100px;
  border-radius: 25px;
  background-color: var(--ProjectColor);
  border: none;
 
 }
 
 .uerregisfooter button:hover {
  background-color: var(--ProjectColorhover);
  color: var(--projectwhite);
  cursor: pointer;
 }

 ::placeholder {
  color: rgb(151, 150, 150);
 }
 @media screen and (max-width: 1193px) {
  
  .user_patienthead {
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    text-align: start;
    width: 95%;
    
   }

 }


 /* Media Query for Responsive Design */
@media screen and (max-width: 768px) {
  .user_row1 {
    margin: 15px;
    width: 350px;
  }

  .user_patienthead {
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    text-align: start;
    width: 93%;
    
   }
}

@media screen and (max-width: 425px){
  /* .user_patienthead {
    background-color: var(--ProjectColor);
    color: black;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    text-align: start;
    width: 90%;
    
   } */

   /* .userhole_container {
    width: calc(100% - 230px); 
   width: 90%;
   margin: 0 auto;
   border: none;
   font-family: Arial, sans-serif;
   color: var(--ProjectColor);
   font-size: 15px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 10px;
   
  } */

  .userform_container{
    width: 100%;
  }

  .user_patienthead {
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    text-align: start;
    width: 90%;
    
   }


   .user_input2 {
    display: flex;
    width: 300px;
  
   
   }
  
   .user_input2 label {
    font-weight: bold;
    text-align: start;
     margin-bottom: 5px;
     padding-top: 7px;
     width: 100px;
     padding: 5px;
     margin-right: 30px;
  
   
   }
  
  
   .user_input2 input {
    border: none;
    outline: none;
    width: 140px;
    font-size: 13px;
    padding: 5px;
    
   }

   .user_row1 {
    margin: 15px;
    width: 90%;
  }
}


























