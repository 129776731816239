/* .Total_input_container{
    width: 100%;
    display: flex;
    gap: 20px 15px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px ;
    box-sizing: border-box;

} */
.Total_input_container_patientData{
    width: 100%;
    display: flex;
    gap: 20px 15px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px ;
    box-sizing: border-box;
}
.Total_input_container{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Define columns, adjust minmax values as needed */
    grid-gap: 20px; /* Specify the gap between grid items */
    justify-content: flex-start;
    align-items: center;
}

.inp_container_all_intakeoutput label{
    width: 120px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--labelcolor);
    font-size: 15px;
    font-weight: 600;
}
.inp_container_all,.inp_container_all_patientData,.inp_container_all_intakeoutput{
    width: auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}
.inp_container_all_patientData span{
    width: auto;
    color: gray;
    align-items: center;
    font-size: 14px;
}
.inp_container_all label{
    width: 120px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--labelcolor);
    font-size: 15px;
    font-weight: 600;
}
.inp_container_all_patientData label{
    width: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--labelcolor);
    font-size: 15px;
    font-weight: 600;
}
.inp_container_all input{
    width: 100px;
    border: 1px solid var(--ProjectColor);
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
}
.inp_container_all_intakeoutput input{
    width: 140px;
    border: 1px solid var(--ProjectColor);
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
}
.inp_container_all_intakeoutput select{
    background-color: var(--selectbackgroundcolor);
    width: 150px;
    border: 0px;
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
}
.inp_container_all select{
    background-color: var(--selectbackgroundcolor);
    width: 110px;
    border: 0px;
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
}
.inp_container_all input:focus{
    border: 1px solid rgba(0, 0, 0, 0.87);
}


.Timeselectorr {
    width: 200px;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns with equal width */
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .Timeselectorr span {
    cursor: pointer;
    width: 23px;
    height: 23px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .Timeselectorr span:hover {
    background-color: #0e76ec;
  }
  .Timeselected {
    background-color: #83a0ee;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }
  .Timeselected:hover{
    background-color: #696865;
  }
  .Timeenabled{
    background-color: rgba(218, 226, 228, 0.705);
    border-radius: 5px;
    color: #000;
    cursor: pointer;
  }
  .Timeenabled:hover{
    background-color: #91b2bd !important;
  }
  .summasearchid{
    position: absolute;
    left: 260px;
    
  }
  .DrugAdministrationTable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  .DrugAdministrationTable table {
    width: auto;
    border-collapse: collapse;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 2px #c4b9b9;
    height: auto;
    padding: 0px 10px;
    box-sizing: border-box;
  }
  
  .DrugAdministrationTable table thead {
    color: var(--ProjectColor);
    width: max-content;
  }
  .DrugAdministrationTable table thead tr th{
    width: max-content;
    padding:  5px;
    box-sizing: border-box;
  }
  .DrugAdministrationTable th,
  .DrugAdministrationTable td {
    padding: 0px 38px;
    height: auto;
    text-align: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
  }
  .DrugAdministrationTable table tbody tr{
    height: 30px;
  }
  .DrugAdministrationTable tr:nth-child(even) {
    background-color: rgba(224, 221, 223, 0.384);
  }
  
  .DrugAdministrationTable tr:hover {
    background-color: #5b595917;
  }
  .qwertyuio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
  }


/* ----------------------------------------- */
  .inputcheckboxcolor_label {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .inputcheckboxcolor_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  
  /* Create a custom checkbox */
  .inputcheckboxcolor_Span {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
  }
  
 
  
  /* When the checkbox is checked, add a blue background */
  .inputcheckboxcolor_label input:checked ~ .inputcheckboxcolor_Span {
    background-color: green;
  }
  .Span_input_clrr{
    background-color: orange !important;
  }
  .Span_input_clrr_111{
    height: 15px;
    width: 15px;
    background-color: red !important;
  }
  
  /* Create the inputcheckboxcolor_Span/indicator (hidden when not checked) */
  .inputcheckboxcolor_Span:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the inputcheckboxcolor_Span when checked */
  .inputcheckboxcolor_label input:checked ~ .inputcheckboxcolor_Span:after {
    display: block !important;
  }
  
  /* Style the inputcheckboxcolor_Span/indicator */
  .inputcheckboxcolor_label .inputcheckboxcolor_Span:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }






@media screen and (max-width:769px) {
    .Total_input_container{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
    }
}