.Medical_History_container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .Medical_History_logo {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .Medical_History_logo_img {
    width: 100px;
    height: auto;
    margin-right: 20px;
  }
  
  .form-section5 {
    margin-top: 10px;
  }
  
  .form-field5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }
  
  .form-field5 label {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    justify-content: space-between;
    gap: 10px;
    color: var(--labelcolor);
  }


  .form-field5 input[type="text"]{
    width: calc(100% - 120px);
    padding: 8px;
    border: none;
    border-bottom: 1px solid var(--ProjectColor);
    border-radius: 4px;
  }

  .form-field5 textarea {
    width: calc(100% - 120px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-field5 textarea {
    height: 30px;
  }
  
  .form-field5 h3 {
    color: var(--ProjectColor);
    font-size: 18px;

  }

  .dkwjd{
    display: flex;
    gap: 10px;
    /* width: 925px; */
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .dkwjd h5{
    display: flex;

    font-size: 13px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  

  .checkbox-label {
    display: flex;
    margin-bottom: 7px;
    font-size: 11px;
    /* font-weight: bold; */
  }
  
  .checkbox-input {
    margin-right: 10px;
  }
  
  .div_ckkck_box{
    width: 200px;
    padding-right: 30px;
    border-right: 1px solid var(--ProjectColor);
  }
.div_ckkkbox_head{
    display: flex;
    gap: 20px;
    justify-content: center;

    align-items: flex-start;
    text-align: start;
}

.alcho_tac_drg11{
  
    border: none;
}
.alcho_tac_drg{
    display: flex;

    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.alcho_tac_drg label{
  display: flex;
  margin-bottom: 0px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.sccx3{
  justify-content: flex-start !important;
}
.alcho_tac_drg11 label{
    width:130px;
}

.ffdff44{
  display: flex;
  gap: 50px;


}

.flx_cjk_labl3{
  display: flex;
  justify-content: space-between;
  text-align: start;
  align-items: flex-start;

}
.form-section56{
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  font-size: 12px;
font-weight: bold;
  justify-content: space-between;
}
.form-section56 input{
  border: none;

  border-bottom: 1px solid var(--ProjectColor);
}
.form-field56 label{
  width: 0x;
  display: flex;

  /* justify-content: space-between; */
}
.form-field56 {
  /* width: 0px; */
  display: flex;
  justify-content: space-between;
}
.form-field56 input{
  width: 150px;
}
/* .alcho_tac_drg label{
    width: 900px !important;
} */


.txtare_div_surgical_head{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  gap: 20px;
}

.txtare_div_surgical{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 20px;
} 

.txtare_div_surgical label{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
  width: 250px;
  font-size: 15px;
  font-weight: bold;
}

.txtare_div_surgical textarea{

  width: 100%;
  height: 40px;
  padding: 5px;
}

.text_fr_surgcl_lbld , .text_fr_surgcl_0{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.iqw7{
  display: flex;
  text-align: start;
  width: 100px;
}
.oii8u{
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
flex-wrap: wrap;
gap: 10px;
width: 315px;
  text-align: center;

}
.sdxsxc343{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sdxsxc343 label
{
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  font-weight:500;
  width: 180px;
  justify-content: flex-start;
  text-align: start;
  align-items: center;
}

.sdxsxc343 input
{
  width: 80px;
  border: none;
  border-bottom:  1px solid var(--ProjectColor);
}

.women_chk_box{
  display: flex;
  flex-direction: column;
}

.w99jdid{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 340px;
  padding-right: 0px;
}

.weedcvt65{
  display: flex;
  flex-direction: column;
}

.women_chk_box h5{
  font-weight: bold;
}

.women_head_men{
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  text-align: center;
  align-items: flex-start;
}
.w99jdid_head{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.weedcvt65_head label{
  display: flex;
  width: 125px;
  font-size: 15px;
  
}

.iojiu7{

  width: 60px;
  border: none;
  margin-left: 1px;
  border-bottom: 1px solid var(--ProjectColor);
}
@media print {
  body {
      font-size: 12px; /* Adjust the font size as needed */
      font-family: Arial, sans-serif;
    }
    .print-button3{
      display: none;
    }
 

    @page :first {
      margin-bottom: -4cm;
      margin-top: -1.5cm;
      
    }
  @page :right {
      margin-right: 1.5cm;
    }
}

@media print {
  .hide-print-button {
      display: none !important;
  }
}


@media screen and (max-width:768px){

  .div_ckkkbox_head{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;

    align-items: center;
    text-align: start;
}

.sccx3{
  display: flex;
  flex-wrap: wrap;

  justify-content: flex-start !important;
}

.ffdff44{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0px;
  margin-bottom: 15px;
}
.dkwjd{
  font-size: 10px;
}
.form-section56 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 12px;
  align-items: center;
  text-align: start;
  justify-content: center;

}
.form-field56 label{
  width: 200px;
  display: flex;
  justify-content: space-between;
}
.form-field56 {
  width: 240px;
  display: flex;
  justify-content: space-between;
}

.text_fr_surgcl_lbld, .text_fr_surgcl_0{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.oii8u{
  width: 260px;
}
.txtare_div_surgical textarea{

  width: 50%;
  height: 40px;
  padding: 5px;
}
.txtare_div_surgical label{
  width: 100px;
} 
}